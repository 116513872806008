import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { LayerConfigWMS } from './types/LayerConfigWMS';
interface LayerSelectorProps {
  layers: LayerConfigWMS[];
  visibleLayers: string[]; // Nouvelle prop pour indiquer les layers déjà affichés
  onLayerOrderChange: (newLayersOrder: LayerConfigWMS[]) => void;
  onLayerClick: (layerId: string) => void;
}

const LayerSelector: React.FC<LayerSelectorProps> = ({ layers, visibleLayers, onLayerOrderChange, onLayerClick }) => {
const [selectedLayers, setSelectedLayers] = useState<string[]>([]);

const handleLayerClick = (layerId: string) => {
  // Vérifier si le layer est déjà affiché
  if (visibleLayers.includes(layerId)) {
    console.warn(`Layer "${layerId}" est déjà affiché. Sélection ignorée.`);
    return; // Ignore l'action si le layer est déjà affiché
  }

  let newSelectedLayers = [...selectedLayers];
  if (selectedLayers.includes(layerId)) {
    newSelectedLayers = selectedLayers.filter(id => id !== layerId); // Désélectionner le layer
  } else {
    newSelectedLayers.push(layerId); // Sélectionner le layer
  }
  setSelectedLayers(newSelectedLayers);
  onLayerClick(layerId);
};

const onDragEnd = (result: DropResult) => {
  const { source, destination } = result;

  if (!destination) return;

  if (source.index !== destination.index) {
    const items = Array.from(layers);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    onLayerOrderChange(items);
  }
};

return (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="layers">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {layers.map((layer, index) => (
            <Draggable key={layer.id} draggableId={String(layer.id)} index={index}>
              {(provided) => (
                <li
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className={`additional-layer-option ${selectedLayers.includes(layer.id) ? 'selected' : ''}`}
                  onClick={() => handleLayerClick(layer.id)}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/layers.png`}
                    alt={layer.name}
                    className="handle"
                  />
                  <span>{layer.name}</span>
                </li>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
);
};

export default LayerSelector;
