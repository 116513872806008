import React, { useState, useEffect } from 'react';
import '../src/css/App.css';
import MapComponent from './components/MapComponent';
import LayerSelector from './components/LayerSelector';
import TimeLine from './components/TimeLine';
import ToggleLayerSelector from './components/ToggleLayerSelector';
import SwitchToggleGrid from './components/SwitchToggleGrid';
import SwitchToggleReference from './components/SwitchToggleReference';
import ToggleButtonDateOverview from './components/ToggleButtonDateOverview';
import ToggleButtonDateOverviewUp from './components/ToggleButtonDateOverviewUp';
import ToggleButtonDateCounterLeft from './components/ToggleButtonDateCounterLeft';
import ToggleButtonDateCounterRight from './components/ToggleButtonDateCounterRight';
import ToggleButtonDateLabel from './components/ToggleButtonDateLabel';
import RangeSlider from './components/RangeSlider';
import IconButton from './components/IconButton';
import LayerSelectorEodyn from './components/LayerSelectorEodyn';
import SatelliteOptions from './components/SatelliteOptions';
import {SatelliteOptionsInterface} from './components/SatelliteOptions';
import { getDaysInMonth} from './components/Utils'
import { LayerConfigWMS } from './components/types/LayerConfigWMS';
import SwipeControl from './components/SwipeControl';

import layersconfig from './data/layers/layersconfig.json';
import Login from './components/auth/Login';


const App: React.FC = () => {




  const [isLayerSelectorVisible, setIsLayerSelectorVisible] = useState(true);

  const toggleLayerSelector = () => {
      //setIsLayerSelectorVisible(!isLayerSelectorVisible);
      setIsLayerSelectorVisible((prev) => !prev);
  };


  
  const [isOverviewVisible, setIsOverviewVisible] = useState(true); // Initialiser la visibilité de l'aperçu de la carte
  //// Modifier la fonction toggleDateAndButtonsVisibility pour gérer également la visibilité de l'aperçu de la carte
  const toggleDateAndButtonsVisibility = () => {
    setIsDateAndButtonsVisible(!isDateAndButtonsVisible);
    setIsOverviewVisible(!isOverviewVisible); // Basculer la visibilité de l'aperçu de la carte en même temps
  };


  const [isDateAndButtonsVisible, setIsDateAndButtonsVisible] = useState(true);
  const toggleDateSelector = () => {
    setIsDateAndButtonsVisible(!isDateAndButtonsVisible);
    setIsOverviewVisible(!isOverviewVisible);
};



    //const handleToggleButtonDateOverview = () => {
    //  console.log("Button clicked : handleToggleButtonDateOverview");
    //};

    //const handleToggleButtonDateOverviewUp = () => {
    //  console.log("Button clicked : handleToggleButtonDateOverviewUp");
    //};

    const handleSliderChange = (value: number) => {
      console.log(`Nouvelle valeur du curseur : ${value}`);
      // Vous pouvez ici mettre à jour l'état ou exécuter d'autres logiques en fonction de la valeur
    };



    const [selectedDate, setSelectedDate] = useState({
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
      hour: new Date().getHours(),
    });
    
    //TODO vérifier le changement de date
    const onSelectDate = (type: 'year' | 'month' | 'day' | 'hour', newValue: number) => {
      setSelectedDate(prevState => {
        //console.log("prevState : "+prevState.year)
        //console.log("prevState : "+prevState.month)
        //console.log("prevState : "+prevState.day)
        //console.log("prevState : "+prevState.hour)
        //console.log("prevState : "+prevState[type])
        //console.log("newValue : "+newValue)

        
        // Vérifie si la nouvelle valeur est différente de la valeur actuelle
        //if (prevState[type] !== newValue) {
          // Si différent, met à jour l'état avec la nouvelle valeur
          return {
            ...prevState,
            [type]: newValue
          };
        //}
        //// Si la valeur n'a pas changé, retourne l'état actuel sans modification
        //return prevState;
      });
    };

    
    
    const incrementDay = () => {
      const daysInMonth = getDaysInMonth(selectedDate.month - 1, selectedDate.year); // Assurez-vous d'ajuster l'index du mois si nécessaire
      if (selectedDate.day < daysInMonth) {
        onSelectDate('day', selectedDate.day + 1);
      } else if (selectedDate.month < 12) {
        // Passer au premier jour du mois suivant
        onSelectDate('month', selectedDate.month + 1);
        onSelectDate('day', 1);
      } else {
        // Passer au premier jour de janvier de l'année suivante si nous sommes en décembre
        onSelectDate('year', selectedDate.year + 1);
        onSelectDate('month', 1); // Janvier, ajustez si vos mois sont 0-indexés
        onSelectDate('day', 1);
      }
      console.log("selectedDate : "+selectedDate.year+" / "+selectedDate.month+" / "+selectedDate.day+" / "+selectedDate.hour)
    };

    
    
  
    const decrementDay = () => {
      if (selectedDate.day > 1) {
        onSelectDate('day', selectedDate.day - 1);
      } else if (selectedDate.month > 1) {
        // Passer au dernier jour du mois précédent
        onSelectDate('month', selectedDate.month - 1);
        const daysInPreviousMonth = getDaysInMonth(selectedDate.month - 2, selectedDate.year); // -2 car les mois sont 0-indexés et nous passons au mois précédent
        onSelectDate('day', daysInPreviousMonth);
      } else {
        // Passer au dernier jour de décembre de l'année précédente si nous sommes en janvier
        onSelectDate('year', selectedDate.year - 1);
        onSelectDate('month', 12); // Décembre, ajustez si vos mois sont 0-indexés
        const daysInDecember = getDaysInMonth(11, selectedDate.year - 1); // 11 représente décembre en index 0
        onSelectDate('day', daysInDecember);
      }
      console.log("selectedDate : "+selectedDate.year+" / "+selectedDate.month+" / "+selectedDate.day+" / "+selectedDate.hour)
    };


    const [mapCenter, setMapCenter] = useState<[number, number]>([6.0, 41.0]); // Initial map center : mexique : [-80, 20.0]
    
    const [mapZoom, setMapZoom] = useState<number>(6); // Initial map zoom
    //const toggleSetMapZoom = () => {
    //  console.log("zoooooooooooooom")
    //  setMapZoom(mapZoom)
    //}

    

    // Layers wms (json config)
    const [visibleLayers, setVisibleLayers] = useState<string[]>([]);
    const toggleLayerVisibility = (layerId: string) => {
      setVisibleLayers(prev => {
        if (prev.includes(layerId)) {
          return prev.filter(id => id !== layerId); // Supprimer la couche si elle est déjà visible
        } else {
          return [...prev, layerId]; // Ajouter la couche si elle n'est pas visible
        }
      });
    };

    // Layers slider map
    const [visibleLayersSlider, setVisibleLayersSlider] = useState<string[]>([]);
    const toggleLayerSliderVisibility = (layerId: string) => {
      setVisibleLayersSlider(prev => {
        if (prev.includes(layerId)) {
          return prev.filter(id => id !== layerId); // Supprimer la couche si elle est déjà visible
        } else {
          return [...prev, layerId]; // Ajouter la couche si elle n'est pas visible
        }
      });
    };

    //const [layers, setLayers] = useState(layersconfig); 
    //const handleLayerOrderChange = (newLayersOrder: LayerConfigWMS[]) => {
    //  setLayers(newLayersOrder);
    //};

    const [layers, setLayers] = useState<LayerConfigWMS[]>(layersconfig); 
    const handleLayerOrderChange = (newLayersOrder: LayerConfigWMS[]) => {
      setLayers(newLayersOrder);
    };

    const [visibleSatelite, setVisibleSatelite] = useState<SatelliteOptionsInterface[]>([]);
    const toggleSateliteVisibility = (layerSatelite: SatelliteOptionsInterface) => {
      setVisibleSatelite(prev => {
          if (prev.length > 0 && prev[0].id === layerSatelite.id) return [];
          else return [layerSatelite];
      });
    };

    const [isDrawingMenuVisible, setIsDrawingMenuVisible] = useState(false);
    const toggleDrawingMenu = () => {
      setIsDrawingMenuVisible(!isDrawingMenuVisible);
    };

    const [isDrawingTargetVisible, setIsDrawingTargetVisible] = useState(false);
    const toggleDrawingTarget = () => {
      setIsDrawingTargetVisible(!isDrawingTargetVisible);
    };


    const [isCapturingMap, setIsCapturingMap] = useState(false);
    const toggleCapturingMap = () => {
      setIsCapturingMap(!isCapturingMap);
    };


    const [isGridOnchange, setIsGridOnchange] = useState(false);
    const handleGridOnChange = () => {
      setIsGridOnchange(!isGridOnchange);
    };

    const [isReferenceOnchange, setIsReferenceOnchange] = useState(false);
    const handleReferenceOnChange = () => {
      setIsReferenceOnchange(!isReferenceOnchange);
    };

    const [isSliderVisible, setIsSliderVisible] = useState(false);
    const toggleSlider = () => {
      setIsSliderVisible(!isSliderVisible);
      setVisibleLayersSlider([]);
    };


    const [swipeValue, setSwipeValue] = useState(50);
    const handleSliderMapChange = (value: number) => {
      //console.log(`Nouvelle valeur du curseur map slider : ${value}`);
      // Vous pouvez ici mettre à jour l'état ou exécuter d'autres logiques en fonction de la valeur
      setSwipeValue(value)
    };

    return (
      
      <div>
        
        <Login/>


        <div id="map"></div>
        <MapComponent 
          center={mapCenter}
          zoom={mapZoom} 
          isOverviewVisible={isOverviewVisible} 
          visibleLayers={visibleLayers} 
          selectedDate={new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day, selectedDate.hour)} 
          visibleSatelite={visibleSatelite}
          layerOrderChange={layers}
          isDrawingMenuVisible={isDrawingMenuVisible}
          toggleCapturingMap={isCapturingMap}
          handleGridOnChange={isGridOnchange}
          handleReferenceOnChange={isReferenceOnchange}
          isDrawingTargetVisible={isDrawingTargetVisible}
          isSliderVisible={isSliderVisible}
          visibleLayersSlider={visibleLayersSlider} 
        />

        <ToggleLayerSelector toggle={toggleLayerSelector}  /* Bouton pour afficher/masquer le sélecteur de couches */ />
          <ul 
            id="layer-selector"
            className={!isLayerSelectorVisible ? 'hide' : ''}
          >
            <h3>Base map</h3>


            <div id="satellite" className="baselayer-option">
              <SatelliteOptions onLayerClick={toggleSateliteVisibility}/>
              <div className="toggle-buttons-basemap-container">
                <div>
                <SwitchToggleGrid isChecked={isGridOnchange} onChange={handleGridOnChange} />
                </div>
                <div>
                  <SwitchToggleReference isChecked={isReferenceOnchange} onChange={handleReferenceOnChange}/>
                </div>
                </div>
            </div>

            <div className="separator"></div>
            <h3>eOdyn layers</h3>
              <LayerSelectorEodyn onLayerClick={toggleLayerVisibility}/>
            <div className="separator"></div>
            <h3>My layers</h3>
              <LayerSelector 
                layers={layers} 
                visibleLayers={visibleLayersSlider}
                onLayerOrderChange={handleLayerOrderChange} 
                onLayerClick={toggleLayerVisibility} 
              />

          </ul>

        {isSliderVisible && (
          <ul id="layer-selector-slider"
          className={!isLayerSelectorVisible ? 'hide' : ''}
          >
            <div className="separator-slider"></div>
            {/*
            <h3>eOdyn layers</h3>
              <LayerSelectorEodyn onLayerClick={toggleLayerSliderVisibility}/>
            <div className="separator-slider"></div>
            */}
            <h3>My layers</h3>
              <LayerSelector 
                layers={layers} 
                visibleLayers={visibleLayers} 
                onLayerOrderChange={handleLayerOrderChange} 
                onLayerClick={toggleLayerSliderVisibility} 
              />          
          </ul>
        )}
          

        {isDateAndButtonsVisible && (
          <>
          <ToggleButtonDateOverview toggle={toggleDateSelector}/>
            <div id="date-and-buttons-container">
              <div id="date-selector-container">
                <TimeLine selectedDate={selectedDate} onSelectDate={onSelectDate} />
                <div id="date-counter-container">
                  <ToggleButtonDateCounterLeft decrementDay={decrementDay}/>
                  <ToggleButtonDateLabel />
                  <ToggleButtonDateCounterRight incrementDay={incrementDay}/>
                  <RangeSlider min={1} max={365} defaultValue={1} onChange={handleSliderChange} />
                </div> 
              </div>

              <div id="custom-buttons-container">
                <IconButton iconPath="101__home.svg" altText="Home" />
                <IconButton iconPath="146__star.svg" altText="Star" />
                <IconButton iconPath="029__cloud_download.svg" altText="Cloud Download" />
                <IconButton iconPath="089__filter.svg" altText="Filter" />
                <IconButton iconPath="083__setting_edit.svg" altText="Settings" onClick={toggleDrawingMenu} />
                <IconButton iconPath="094__camera.svg" altText="Camera" onClick={toggleCapturingMap}/>
                <IconButton iconPath="087__chart.svg" altText="Chart" />
                <IconButton iconPath="128__eye.svg" altText="Eye" onClick={toggleSlider}/>
                <IconButton iconPath="buoy.svg" altText="Derive" onClick={toggleDrawingTarget} />
              </div>
            </div>
          </>
        )}
        {!isDateAndButtonsVisible && <ToggleButtonDateOverviewUp toggle={toggleDateAndButtonsVisibility} />}

        <div id="eodyn-logo-container">
          <img src={`${process.env.PUBLIC_URL}/images/icon/eodyn-logo-blanc2.png`} alt="eOdyn" />
        </div>
        
        <div>
          {isSliderVisible && (
            <>
              <SwipeControl min={0} max={100} defaultValue={50} onChange={handleSliderMapChange}/>
            </>
          )}
        </div>
    
      </div>

    );
};

export default App;