import * as maptalks from 'maptalks';
import LayerCache from '../LayerCache';

import { getLayerConfigWMS, getLayerConfigEOdyn, getLayerRange } from '../utils/LayerConfig'

import { 
    createRasterLayer,
    createMarkersLayer,
    createEddiesLayer,
    createDriftersLayer,
    createWindyLayer,
    createDriftersOpendriftLayer,
} from '../utils/CreateLayer'

import { getTimeFormatWMS, buildWmsUrl } from '../Utils'
import { LayerConfigEOdyn } from '../types/LayerConfigEOdyn';

function jsonToLayer(map: maptalks.Map, jsonData: any,layerData: LayerConfigEOdyn, layerId: string, date: Date): maptalks.Layer | null {
    let vectorLayer: null|maptalks.Layer = null;
    switch (layerData.type) {
        case "markersLayer":
            vectorLayer = createMarkersLayer(jsonData, layerId, date);
            break;
        case "eddiesLayer":
            vectorLayer = createEddiesLayer(jsonData, layerId);
            break;
        case "driftersLayer":
            vectorLayer = createDriftersLayer(jsonData, layerId);
            break;
        case "windyLayer":
            vectorLayer = createWindyLayer(jsonData, layerId);
            break;
        case "opendriftDrifterLayer":
            const layerTarget: any = map.getLayer("target");
            console.log(layerTarget)
            if (layerTarget != null && layerTarget !== undefined) {
                //console.log(layerTarget.getLastGeometry().getCenter()["x"]);
                //console.log(layerTarget.getLastGeometry().getCenter()["y"]);
                //console.log(layerTarget.getLastGeometry().toJSON()["radius"]/1e4);
                vectorLayer = createDriftersOpendriftLayer(
                    jsonData,
                    layerId,
                    layerTarget.getLastGeometry().getCenter()["x"],
                    layerTarget.getLastGeometry().getCenter()["y"],
                    layerTarget.getLastGeometry().toJSON()["radius"]/1000,
                );
                //vectorLayer = createE3Layer(
                //    jsonData,
                //    layerId,
                //    layerTarget.getLastGeometry().getCenter()["x"],
                //    layerTarget.getLastGeometry().getCenter()["y"],
                //    layerTarget.getLastGeometry().toJSON()["radius"]/1000,
                //);
            } else {
                console.log("########### NO TARGET")
            }
            //vectorLayer = createDriftersLayer(jsonData, layerId);
            //vectorLayer = createE3Layer(jsonData, layerId);
            break;
        //case "OSRasterLayer":
        //    vectorLayer = createRasterLayer(jsonData, layerId, date);
        //    break;
        default:
            console.log(`Warning: newVectorLayer for id: ${layerId} not yet available`);
            return null;
        
    }

    return vectorLayer
}

export function downloadJsonLayer(path: string, maincallback: (jsonData: any) => void, errorcallback: (error: any) => void): maptalks.Layer | null {

    console.log("path:", path);
    const token = localStorage.getItem('authToken');
    fetch(path, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`, // <-- Add the token here
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`Erreur de chargement du fichier JSON: ${response.statusText}`);
        }
        return response.json();
    })
    .then(jsonData => {
        console.log("jsonData:", jsonData);
        maincallback(jsonData)
        
    })
    .catch(error => {
        errorcallback(error)
    });

    return null
}


function createPath(layerId: string, map: maptalks.Map, layerData: LayerConfigEOdyn, date: Date): string {
    let path: string;
    //if (layerId === "markersLayer"){
    //    path = `${layerData.path}${date.toISOString().split('T')[0].replaceAll("-", "")}_${date.getHours().toString()}_${layerData.tag}.json`;
    //} else {
    //    path = `${layerData.path}${date.toISOString().split('T')[0].replaceAll("-", "")}_${layerData.tag}.json`;
    //}

    //if (layerId === "windyLayer"){
    //    path = `/api_eodyn/data/windyLayer/_${layerData.tag}/${date.toISOString().split('T')[0].replaceAll("-", "")}/json`
    //}
    if (layerId === "opendriftDrifterLayer"){
        const layerTarget: any = map.getLayer("target");
        if (layerTarget != null && layerTarget !== undefined) {
            const x = layerTarget.getLastGeometry().getCenter()["x"]
            const y = layerTarget.getLastGeometry().getCenter()["y"]
            const radius = layerTarget.getLastGeometry().toJSON()["radius"]/1000
            path = `${layerData.path}/_${layerData.tag}/${date.toISOString().split('T')[0].replaceAll("-", "")}/json/${x}/${y}/${radius}`
        } else {
            path = `${layerData.path}/_${layerData.tag}/${date.toISOString().split('T')[0].replaceAll("-", "")}/json`
        }
        
    } else if (layerId === "markersLayer"){
        //const dateday = date.getHours().toString().padStart(2, '0');
        path = `${layerData.path}/_${layerData.tag}/${date.toISOString().split('T')[0].replaceAll("-", "")}_00/json`
        //path = `${layerData.path}/_${layerData.tag}/${date.toISOString().split('T')[0].replaceAll("-", "")}_${dateday}/json`
    }else {
        path = `${layerData.path}/_${layerData.tag}/${date.toISOString().split('T')[0].replaceAll("-", "")}/json`
    }

    return path;
}

function removeAllTmpLayer(layerId: string, map: maptalks.Map){
    var ids: string[] = [];
    map.getLayers().forEach((layer: maptalks.Layer)=> {
        if (layer.getId().includes(layerId) && layer.getId().includes("tmp")){
            console.log("%:",layer.getId())
            ids.push(layer.getId())
        }
    })

    ids.forEach((id: string) => {
        map.removeLayer(id);
    })
}

function removeTmpLayer(layerId: string, map: maptalks.Map, ms: number = 2000){
    if (map.getLayer("tmp_"+layerId))map.removeLayer("tmp_"+layerId);
    
    //setTimeout(() => {
    //    if (map.getLayer("tmp_"+layerId)) {
    //        map.removeLayer("tmp_"+layerId);
    //        console.log("Temp layer removed: tmp_", layerId);
    //        removeAllTmpLayer(layerId, map)
    //    }
    //}, ms);

}

function setTmpLayer(layerId: string, map: maptalks.Map){
    if (map.getLayer(layerId)) {
        if (map.getLayer("tmp_"+layerId))map.removeLayer("tmp_"+layerId);
        map.getLayer(layerId).setId("tmp_"+layerId).setOpacity(0.8);
        console.log("TMP TRANSFORM: tmp_",layerId)
    }
}

export function newVectorLayer(cache: LayerCache, map: maptalks.Map, layerId: string, date: Date, endId: string = "" ) {
    const layerData = getLayerConfigEOdyn(layerId);
    if (!layerData) return null;

    const dateRange: Date[] | null = getLayerRange(layerData);
    if (dateRange){
        const [minRange, maxRange] = dateRange;
        if (!(date >= minRange && date <= maxRange)){
            console.log("Out of range for id : ", layerId,"-",date.toString())
            if (map.getLayer(layerId))map.removeLayer(layerId);
            var vectorLayerDefault = new maptalks.VectorLayer(layerId);
            vectorLayerDefault.setZIndex(1000);
            map.addLayer(vectorLayerDefault);
            return
        }
    } else {
        console.log("No range define for id : ", layerId,"-",date.toString())
    }

    setTmpLayer(layerId, map)

    const cachedLayer: maptalks.Layer | null = cache.getLayer(layerId, layerData.type, date);
    if (cachedLayer) {
        var layer: maptalks.Layer = cachedLayer;

        //TODO : windyLayerSwitch
        //if (layerId === "windyLayer2") {
        //    const cachedLayerBis: maptalks.Layer | null = cache.getLayer(layerId+"bis", layerData.type, date);
        //    if (cachedLayerBis){
        //        layer = cachedLayerBis
        //    }
        //}

        if (endId !== "") {
            layer.setId(endId);
        }

        map.addLayer(layer.setZIndex(1000).setId(layerId));
        removeTmpLayer(layerId,map);
        return
    } else {
        console.log("Aucun layer trouvé dans le cache pour les métadonnées spécifiées.");
    }

    if (layerId.includes("OSRasterLayer")) {
        createRasterLayer(map, layerId, date);
        if (map.getLayer("tmp_"+layerId))map.removeLayer("tmp_"+layerId);
        return;
    }

    // Disable FailedRessource condition because of two many request from eodyn api
    //if (cache.isFailedRessource(layerId, layerData.type, date)) {
    //    //var vectorLayer = new maptalks.VectorLayer(layerId);
    //    //vectorLayer.setZIndex(1000);
    //    //map.addLayer(vectorLayer);
    //    if (map.getLayer("tmp_"+layerId))map.removeLayer("tmp_"+layerId);
    //    return
    //}
    
    var path = createPath(layerId, map, layerData, date);
    
    downloadJsonLayer(
        path,
        (jsonData) => {
            let vectorLayer = jsonToLayer(
                map,
                jsonData,
                layerData,
                layerId,
                date,
            )

            if (vectorLayer) {
                vectorLayer = vectorLayer as maptalks.Layer
                if (endId !== "") {
                    vectorLayer.setId(endId)
                }
                if (map.getLayer(layerId)) {
                    console.warn("Too fast change for layer id : ", layerId);
                    map.removeLayer(layerId);
                }
                map.addLayer(vectorLayer.setZIndex(4000));                
                cache.saveLayer(layerId, layerData.type, date, vectorLayer);
                removeTmpLayer(layerId,map);

                //TODO : windyLayerSwitch
                //if (layerId === "windyLayer2") {
                //    windyLayerSwitch(cache, map, layerId, date);
                //}
            } else {
                if (map.getLayer("tmp_"+layerId))map.removeLayer("tmp_"+layerId);
            }
        },
        (error) => {
            console.error('Error fetching data:', error);
            //var vectorLayer = new maptalks.VectorLayer(layerId);
            //vectorLayer.setZIndex(1000);
            //map.addLayer(vectorLayer);
            cache.addFailedRessource(layerId, layerData.type, date)
            if (map.getLayer("tmp_"+layerId))map.removeLayer("tmp_"+layerId);
        },
    )
}

export function windyLayerSwitch(cache: LayerCache, map: maptalks.Map, layerId: string, date: Date) {
    console.log("windyLayerSwitch : " + layerId)
    const layerData = getLayerConfigEOdyn(layerId);
    if (!layerData) return null;
    if (date > new Date()) {
        return;
    }
    if (!layerId.includes("windyLayer2")) {
        return
    }
    const dateRange: Date[] | null = getLayerRange(layerData);
    if (dateRange){
        const [minRange, maxRange] = dateRange;
        if (!(date >= minRange && date <= maxRange)){
            //console.log("Cache : Out of range for id : ", layerId,"-",date.toString())
            return
        }
    } else {
        console.log("Cache : No range define for id : ", layerId,"-",date.toString())
    }

    const layerIndex = map.getLayer(layerId).getZIndex()
    const layerIdBis = layerId+"bis";

    if (cache.isFailedRessource(layerIdBis, layerData.type, date)) {
        return
    }

    var tag = layerData.tag.replace(/.$/, "");
    tag = tag+"2"

    var path = `${layerData.path}/_${tag}/${date.toISOString().split('T')[0].replaceAll("-", "")}/json`
    console.log("windy path : ", path)

    downloadJsonLayer(
        path,
        (jsonData) => {
            let vectorLayer = jsonToLayer(
                map,
                jsonData,
                layerData,
                layerId,
                date,
            )

            if (vectorLayer) {
                vectorLayer = vectorLayer as maptalks.Layer
                cache.saveLayer(layerIdBis, layerData.type, date, vectorLayer);
                setTmpLayer(layerId, map)
                map.addLayer(vectorLayer.setZIndex(layerIndex).setId(layerId));
                // Programmer la suppression du calque temporaire après 5 secondes
                removeTmpLayer(layerId,map);
            }
        },
        (error) => {
            console.error('Error of preloadind ',layerData.id ,' data: date : ', date, " error : ",error);
            cache.addFailedRessource(layerId, layerData.type, date)
            if (map.getLayer("tmp_"+layerId))map.removeLayer("tmp_"+layerId);
        },
    )
}

export function cacheVectorLayer(cache: LayerCache, map: maptalks.Map, layerId: string, date: Date, preloading: number, endId: string = "" ) {
    const layerData = getLayerConfigEOdyn(layerId);
    if (!layerData) return null;

    const daysOffsets = [
        ...Array.from({ length: preloading }, (_, i) => -preloading + i),
        ...Array.from({ length: preloading }, (_, i) => i + 1)
    ];

    const dates = daysOffsets.map(offset => {
        const newDate = new Date(date); // Copie de la date de départ
        newDate.setDate(date.getDate() + offset); // Ajoute le nombre de jours décalé
        return newDate;
    });

    dates.forEach(date => {
        if (date > new Date()) {
            return;
        }

        if (!layerData.type.includes("windyLayer")) {
            return
        }

        const dateRange: Date[] | null = getLayerRange(layerData);
        if (dateRange){
            const [minRange, maxRange] = dateRange;
            if (!(date >= minRange && date <= maxRange)){
                console.log("Cache : Out of range for id : ", layerId,"-",date.toString())
                return
            }
        } else {
            console.log("Cache : No range define for id : ", layerId,"-",date.toString())
        }

        if (cache.getLayer(layerId, layerData.type, date)) {
            return
        }

        if (cache.isFailedRessource(layerId, layerData.type, date)) {
            return
        }

        var path = createPath(layerId, map, layerData, date);

        downloadJsonLayer(
            path,
            (jsonData) => {
                let vectorLayer = jsonToLayer(
                    map,
                    jsonData,
                    layerData,
                    layerId,
                    date,
                )
    
                if (vectorLayer) {
                    vectorLayer = vectorLayer as maptalks.Layer
                    if (endId !== "") {
                        vectorLayer.setId(endId)
                    }
                    cache.saveLayer(layerId, layerData.type, date, vectorLayer.setZIndex(1000));
                    cache.afficheCache()
                }
                //cacheVectorLayer(cache, map, layerId, date, 3)
            },
            (error) => {
                console.error('Error of preloadind ',layerData.id ,' data: date : ', date, " error : ",error);
                cache.addFailedRessource(layerId, layerData.type, date)
            },
        )
    });
}

export function vectorLayerWMS(cache: LayerCache, map: maptalks.Map, layerId: string, date: Date, index: number, endId: string = "") { 
    const layerData = getLayerConfigWMS(layerId);
    if (layerData) {
        const timeFormat = getTimeFormatWMS(date.toISOString(),layerData.dateformat);
        let layer: maptalks.WMSTileLayer | maptalks.TileLayer;

        if (layerId.toLowerCase().includes("wmts")) {

            layer = new maptalks.TileLayer(layerId, {
                urlTemplate: layerData.urlTemplate +
                             "layer="+layerData.layers +
                             "&tilematrixset="+layerData.srs+"&tilematrix={z}&tilerow={y}&tilecol={x}&time="+timeFormat,
                spatialReference: {
                    projection: layerData.srs,
                },
                opacity: layerData.opacity,
            });
        } else {

            layer = new maptalks.WMSTileLayer(layerId, {
                tileSystem: [1,-1,180,90],
                urlTemplate: buildWmsUrl(layerData, timeFormat),
                layers: layerData.layers, // Ajouter la propriété layers ici
                //opacity: 1,//min(layerData.opacity ,0.5),
                //transparent: true,
                //crs: layerData.srs//'EPSG:4326', // TODO cause disfonctionement other
                
                styles: layerData.styles,
                format: 'image/png',
                transparent: true,
                version: layerData.version,
                crs: layerData.srs,
                tileRetryCount: 4,
            });
        }

        layer.setZIndex(index);
        //layer.setOptions({ opacity: 0.5 });
        if (endId != "") {
            layer.setId(endId)
            console.log("vectorLayerWMS:setId")
        }
        map.addLayer(layer);
        console.log("vectorLayerWMS:getLayerConfigWMS: ADD")
    }
}